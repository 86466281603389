import React from "react"
import PolicyLayout from "../../components/policy-layout"
import ArrowIcon from "../../images/arrow3.svg"

const PrivacyPage = () => {
  return (
    <PolicyLayout>
      {({ toggle }) => (
        <div className="privacy-page">
          <div className="wt-flex" onClick={toggle}>
            <h1>Privacy Policy</h1>
            <img
              src={ArrowIcon}
              alt="arrow"
              className="wt-margin-left-10 display-lg-none display-none"
            />
          </div>

          <p>
            Thanks for using WebTotem! Here, we describe how we collect, use and
            handle your information when you use our websites, software and
            services.
          </p>

          <h2>Collected Information</h2>

          <p>
            We collect and use personal data only as it might be needed for us
            or instructed by you to deliver you our products, services, websites
            applications (collectively, our “Services”). Your personal data
            includes information such as: <br />
            <br />
            • Name <br />
            • Email address <br />
            • Other data collected that could directly or indirectly identify
            you.
            <br />
            <br />
            We collect information so that we can provide the best possible
            experience when you utilize our Services. Much of what you likely
            consider personal data is collected directly from you when you:
            <br />
            <br />
            • create an account or purchase any of our Services; <br />• request
            assistance from our support team (ex: phone number); <br />•
            complete contact forms or request newsletters or other information
            from us (ex: email);
          </p>

          <h2>Information Usage</h2>

          <p>
            We may use the information we collect for certain purposes,
            including the following:
            <br />
            <br />
            • Maintain and provide our Site, products, and services <br />
            • Fulfill the purpose for which you provide it <br />
            • Personalize your experience <br />
            • Improve our Site, products and services <br />
            • Develop new products and services <br />
            • Improve security <br />
            • Detect and prevent fraud <br />
            • Protect our and our visitors' safety <br />
            • Analyze and monitor activity on our Site <br />
            • Comply with our obligations <br />
            • Marketing <br />
            • Exercise our rights <br />
            • Enforce our contracts <br />• Information Sharing
          </p>

          <h2>
            We may share your information in certain circumstances, including
            the following:
          </h2>

          <p>
            With Service Providers and Vendors: <br />
            <br />
            We may retain other companies and individuals to perform services
            for us, and we may collaborate with other companies and individuals
            with respect to particular products or services (collectively,
            “Providers”). In doing so, we may share information with these
            Providers and/or these Providers may collect and process your
            information. Examples of Providers include data analysis firms (e.g.
            Google Analytics), customer service and support providers, payment
            processors, and internet service companies. These companies are
            authorized to use your personal information only as necessary to
            provide these services to us. <br />
            <br />
            We may also disclose your personal information to any other third
            party with your prior consent.
          </p>

          <h2>Cookies & Other Tools</h2>

          <p>
            Our Site uses cookies to identify you so you don't need to log in
            each time you visit our Site. The cookies are linked to your
            customer identifier, which is associated with the information in
            your account. If you reject cookies, you may still use our Site and
            applications, but your ability to use some features or areas may be
            limited. <br />
            <br />
            Within our Site we and our partners, affiliates, and/or analytics or
            service providers use technologies such as cookies, beacons, tags
            and scripts to analyze trends, administer the Site, track visitors'
            movements around the Site and to gather demographic information
            about our visitor base as a whole. We may receive reports based on
            the use of these technologies by these companies on an individual or
            aggregated basis.
            <br />
            <br />
            As is true of most websites, we gather certain information
            automatically and store it in log files. This information may
            include Internet protocol (IP) addresses, browser type, Internet
            service provider (ISP), referring/exit pages, operating system,
            date/time stamp and/or clickstream data. We use this information
            gathered about you from our Site to help personalize search results,
            diagnose problems with our server, and to administer our Site. We
            also gather broad demographic information from this data to help us
            improve our Site and make your browsing and purchasing experience
            more enjoyable. This is not linked to any personally identifiable
            information, except as necessary to prevent fraud or abuse on our
            system. <br />
            <br />
            You can set your browser to notify you when you receive a cookie and
            you can refuse it; this may, however, reduce functionality of
            certain Site features.
          </p>

          <h2>Targeted Advertisements</h2>

          <p>
            Absent any instructions from you, Webtotem may use information you
            provide to inform you about additional services and products offered
            by Webtotem’s affiliates, Webtotem venture companies, Webtotem
            authorized agents, and, where you have consented or where permitted
            under applicable law, other goods and services providers with whom
            Webtotem has relationships and whose offerings might be of interest
            to you. Webtotem and our third party partners may use cookies or
            similar technologies in order to provide you advertising based upon
            your browsing activities and interests. Please note you will
            continue to receive generic ads.
          </p>

          <h2>Security</h2>

          <p>
            The security of your personally identifiable information is
            important to us. We follow generally accepted standards to protect
            the personally identifiable information submitted to us, both during
            transmission and once it is received. Even though we take
            precautions to protect information, no security measures can be 100%
            secure, and we cannot guarantee the security of your information. If
            you have any questions about the security of your personally
            identifiable information, you can contact us at support@wtotem.com.
          </p>

          <h2>Data Retention</h2>

          <p>
            We may retain your information for as long as your account is active
            or as needed to provide you services, comply with our legal
            obligations, resolve disputes, and enforce our agreements.
          </p>

          <h2>Visitor Consent</h2>

          <p>
            Information that you disclose in a public space, including on any
            blog, bulletin board, chat room, or website Webtotem may host for
            you as part of your Webtotem services, is available to anyone else
            who visits that space. Webtotem cannot safeguard any information you
            disclose in these locations.
            <br />
            If you voluntarily offer any feedback, data, answers, questions,
            comments, suggestions, ideas or the like, Webtotem will treat that
            portion of the information as non-confidential and nonproprietary
            and, except as otherwise expressed in this Policy, Webtotem assumes
            no obligation to protect such information from disclosure. To
            request removal of your personally identifiable information from our
            blog or community forum, contact us at support@wtotem.com. In some
            cases, we may not be able to remove your personally identifiable
            information, in which case we will let you know if we are unable to
            do so and why.
          </p>

          <h2>Access</h2>

          <p>
            Upon request, we will provide you with a copy of your account data
            (EU customers only), or information about whether we hold, or
            process on behalf of a third party, any of your personally
            identifiable information. To request this information please contact
            us at support@wtotem.com.
            <br />
            You may also correct or request deletion of your personally
            identifiable information by contacting us at support@wtotem.com. We
            will respond to these requests within a reasonable timeframe.
          </p>

          <h2>Updates</h2>

          <p>
            We may update this Policy to reflect changes to our information
            practices. If we make any material changes, we will notify you by
            email (sent to the e-mail address specified in your account) or by
            means of a notice on this Site prior to the change becoming
            effective. We encourage you to periodically review this page for the
            latest information on our privacy practices.
          </p>
        </div>
      )}
    </PolicyLayout>
  )
}
export default PrivacyPage
